body{
  margin: 0;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.App {
  text-align: center;
}

.App-header {
  background-color: #121213;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  font-size: calc(10px + 2vmin);
  color: white;
}

.main-title {
  font-size: 36px;
  font-weight: 850;
}

.credits {
  font-size: 6px;
  position: fixed;
  right: 3px;
  bottom: 0px;
}

.credits-1 {
  font-size: 6px;
  position: fixed;
  right: 3px;
  bottom: 8px;
}

.title-layout {
  display : flex;
  flex-direction: row;
}

.knight{
  
}