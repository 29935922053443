
.rank {
    display : flex;
    margin-bottom: 20px;
    gap: 6px;
}

.btn {
  background-color: #fbeee0;
  border: 2px solid #422800;
  border-radius: 30px;
  box-shadow: #422800 4px 4px 0 0;
  color: #422800;
  cursor: pointer;
  display: inline-block;
  font-weight: 800;
  font-size: 12px;
  padding: 0 12px;
  line-height: 50px;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.btn:hover {
  background-color: #fff;
}

.btn:active {
  box-shadow: #422800 2px 2px 0 0;
  transform: translate(2px, 2px);
}